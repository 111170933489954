<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="12">
          <h1 class="mb-3 text-center">В данный момент сайт недоступен</h1>
          <p class="text-muted text-center">Ведутся технические работы</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  name: "verification",
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
</style>
